import * as i0 from '@angular/core';
import { InjectionToken, PLATFORM_ID, Injectable, Inject, Optional, Directive, NgModule } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { coerceElement } from '@angular/cdk/coercion';
import { getRtlScrollAxisType } from '@angular/cdk/platform';
import { Subject, merge, fromEvent, Observable, animationFrameScheduler, of } from 'rxjs';
import { take, expand, takeWhile, takeUntil, finalize } from 'rxjs/operators';
import BezierEasing from 'bezier-easing';
const SMOOTH_SCROLL_OPTIONS = new InjectionToken('SMOOTH_SCROLL_OPTIONS');

// @dynamic
class SmoothScrollManager {
  get _w() {
    return this._document.defaultView;
  }
  /**
   * Timing method
   */
  get _now() {
    return this._w.performance && this._w.performance.now ? this._w.performance.now.bind(this._w.performance) : Date.now;
  }
  constructor(_document, _platform, customDefaultOptions) {
    this._document = _document;
    this._platform = _platform;
    // Keeps track of the ongoing SmoothScroll functions so they can be handled in case of duplication.
    // Each scrolled element gets a destroyer stream which gets deleted immediately after it completes.
    // Purpose: If user called a scroll function again on the same element before the scrolls completes,
    // it cancels the ongoing scroll and starts a new one
    this._onGoingScrolls = new Map();
    this._defaultOptions = {
      duration: 468,
      easing: {
        x1: 0.42,
        y1: 0,
        x2: 0.58,
        y2: 1
      },
      ...customDefaultOptions
    };
  }
  /**
   * changes scroll position inside an element
   */
  _scrollElement(el, x, y) {
    el.scrollLeft = x;
    el.scrollTop = y;
  }
  /**
   * Handles a given parameter of type HTMLElement, ElementRef or selector
   */
  _getElement(el, parent) {
    if (typeof el === 'string') {
      return (parent || this._document).querySelector(el);
    }
    return coerceElement(el);
  }
  /**
   * Initializes a destroyer stream, re-initializes it if the element is already being scrolled
   */
  _initSmoothScroll(el) {
    if (this._onGoingScrolls.has(el)) {
      this._onGoingScrolls.get(el).next();
    }
    return this._onGoingScrolls.set(el, new Subject()).get(el);
  }
  /**
   * Checks if smooth scroll has reached, cleans up the smooth scroll stream and resolves its promise
   */
  _isFinished(context, destroyed, resolve) {
    if (context.currentX !== context.x || context.currentY !== context.y) {
      return true;
    }
    destroyed.next();
    resolve();
    return false;
  }
  /**
   * Terminates an ongoing smooth scroll
   */
  _interrupted(el, destroyed) {
    return merge(fromEvent(el, 'wheel', {
      passive: true,
      capture: true
    }), fromEvent(el, 'touchmove', {
      passive: true,
      capture: true
    }), destroyed).pipe(take(1));
  }
  /**
   * Deletes the destroyer function, runs if the smooth scroll has finished or interrupted
   */
  _destroy(el, destroyed) {
    destroyed.complete();
    this._onGoingScrolls.delete(el);
  }
  /**
   * A function called recursively that, given a context, steps through scrolling
   */
  _step(context) {
    return new Observable(subscriber => {
      let elapsed = (this._now() - context.startTime) / context.duration;
      // avoid elapsed times higher than one
      elapsed = elapsed > 1 ? 1 : elapsed;
      // apply easing to elapsed time
      const value = context.easing(elapsed);
      context.currentX = context.startX + (context.x - context.startX) * value;
      context.currentY = context.startY + (context.y - context.startY) * value;
      this._scrollElement(context.scrollable, context.currentX, context.currentY);
      // Proceed to the step
      animationFrameScheduler.schedule(() => subscriber.next(context));
    });
  }
  _applyScrollToOptions(el, options) {
    if (!options.duration) {
      this._scrollElement(el, options.left, options.top);
      return Promise.resolve();
    }
    // Initialize a destroyer stream, reinitialize it if the element is already being scrolled
    const destroyed = this._initSmoothScroll(el);
    const context = {
      scrollable: el,
      startTime: this._now(),
      startX: el.scrollLeft,
      startY: el.scrollTop,
      x: options.left == null ? el.scrollLeft : ~~options.left,
      y: options.top == null ? el.scrollTop : ~~options.top,
      duration: options.duration,
      easing: BezierEasing(options.easing.x1, options.easing.y1, options.easing.x2, options.easing.y2)
    };
    return new Promise(resolve => {
      // Scroll each step recursively
      of(null).pipe(expand(() => this._step(context).pipe(takeWhile(currContext => this._isFinished(currContext, destroyed, resolve)))), takeUntil(this._interrupted(el, destroyed)), finalize(() => this._destroy(el, destroyed))).subscribe();
    });
  }
  /**
   * Scrolls to the specified offsets. This is a normalized version of the browser's native scrollTo
   * method, since browsers are not consistent about what scrollLeft means in RTL. For this method
   * left and right always refer to the left and right side of the scrolling container irrespective
   * of the layout direction. start and end refer to left and right in an LTR context and vice-versa
   * in an RTL context.
   * @param scrollable element
   * @param customOptions specified the offsets to scroll to.
   */
  scrollTo(scrollable, customOptions) {
    if (isPlatformBrowser(this._platform)) {
      const el = this._getElement(scrollable);
      const isRtl = getComputedStyle(el).direction === 'rtl';
      const rtlScrollAxisType = getRtlScrollAxisType();
      const options = {
        ...this._defaultOptions,
        ...customOptions,
        ...{
          // Rewrite start & end offsets as right or left offsets.
          left: customOptions.left == null ? isRtl ? customOptions.end : customOptions.start : customOptions.left,
          right: customOptions.right == null ? isRtl ? customOptions.start : customOptions.end : customOptions.right
        }
      };
      // Rewrite the bottom offset as a top offset.
      if (options.bottom != null) {
        options.top = el.scrollHeight - el.clientHeight - options.bottom;
      }
      // Rewrite the right offset as a left offset.
      if (isRtl && rtlScrollAxisType !== 0 /* RtlScrollAxisType.NORMAL */) {
        if (options.left != null) {
          options.right = el.scrollWidth - el.clientWidth - options.left;
        }
        if (rtlScrollAxisType === 2 /* RtlScrollAxisType.INVERTED */) {
          options.left = options.right;
        } else if (rtlScrollAxisType === 1 /* RtlScrollAxisType.NEGATED */) {
          options.left = options.right ? -options.right : options.right;
        }
      } else {
        if (options.right != null) {
          options.left = el.scrollWidth - el.clientWidth - options.right;
        }
      }
      return this._applyScrollToOptions(el, options);
    }
    return Promise.resolve();
  }
  /**
   * Scroll to element by reference or selector
   */
  scrollToElement(scrollable, target, customOptions = {}) {
    const scrollableEl = this._getElement(scrollable);
    const targetEl = this._getElement(target, scrollableEl);
    const options = {
      ...customOptions,
      ...{
        left: targetEl.offsetLeft + (customOptions.left || 0),
        top: targetEl.offsetTop + (customOptions.top || 0)
      }
    };
    return targetEl ? this.scrollTo(scrollableEl, options) : Promise.resolve();
  }
}
SmoothScrollManager.ɵfac = function SmoothScrollManager_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SmoothScrollManager)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(SMOOTH_SCROLL_OPTIONS, 8));
};
SmoothScrollManager.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: SmoothScrollManager,
  factory: SmoothScrollManager.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SmoothScrollManager, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [SMOOTH_SCROLL_OPTIONS]
      }]
    }];
  }, null);
})();
class SmoothScroll {
  constructor(element, smoothScroll) {
    this.element = element;
    this.smoothScroll = smoothScroll;
  }
  scrollTo(options) {
    return this.smoothScroll.scrollTo(this.element, options);
  }
  scrollToElement(target, options) {
    return this.smoothScroll.scrollToElement(this.element, target, options);
  }
}
SmoothScroll.ɵfac = function SmoothScroll_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SmoothScroll)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(SmoothScrollManager));
};
SmoothScroll.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: SmoothScroll,
  selectors: [["", "smoothScroll", ""], ["", "smooth-scroll", ""]],
  exportAs: ["smoothScroll"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SmoothScroll, [{
    type: Directive,
    args: [{
      selector: '[smoothScroll], [smooth-scroll]',
      exportAs: 'smoothScroll'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: SmoothScrollManager
    }];
  }, null);
})();
class SmoothScrollModule {}
SmoothScrollModule.ɵfac = function SmoothScrollModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SmoothScrollModule)();
};
SmoothScrollModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SmoothScrollModule
});
SmoothScrollModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SmoothScrollModule, [{
    type: NgModule,
    args: [{
      declarations: [SmoothScroll],
      exports: [SmoothScroll]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SMOOTH_SCROLL_OPTIONS, SmoothScroll, SmoothScrollManager, SmoothScrollModule };
